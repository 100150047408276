import { Injectable } from '@angular/core';
import { Http } from './http';
import { environment } from 'src/environments/environment';

import inputmask from 'inputmask';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  url = environment.url;
  user: any;

  loading = false;

  actualPageTitle;
  actualPageButtonAction;
  actualPageButtonTitle;

  constructor(private http: Http) {
    // this.getStatus();
  }

  setMasks() {
    setTimeout(() => {
      inputmask("currency", {
        "autoUnmask": true,
        prefix: "R$ ",
        allowMinus: false,
        unmaskAsNumber: true,
        inputType: "number",
        radixPoint: ',',
      }).mask(document.querySelectorAll(".mask-currency"));
      inputmask("integer").mask(document.querySelectorAll(".mask-integer"));
      inputmask("integer", {
        "autoUnmask": true,
        allowMinus: false,
        unmaskAsNumber: true,
        suffix: " m²",
      }).mask(document.querySelectorAll(".mask-meters"));
      inputmask("datetime",
        {
          inputFormat: "dd/mm/yyyy",
          "autoUnmask": true,
          "outputFormat": "yyyy-mm-dd",
          "clearIncomplete": true
        })
        .mask(document.querySelectorAll(".mask-date"));
      inputmask("datetime",
        {
          inputFormat: "dd/mm/yyyy",
          "clearIncomplete": true
        })
        .mask(document.querySelectorAll(".mask-date-material"));
      inputmask({ mask: "99.999-999", "autoUnmask": true }).mask(document.querySelectorAll(".mask-cep"));
      inputmask({ mask: "999.999.99-99", "autoUnmask": true }).mask(document.querySelectorAll(".mask-cpf"))
      inputmask({ mask: "(99) 99999-9999", "autoUnmask": true }).mask(document.querySelectorAll(".mask-phone"))
      inputmask({ mask: "9[9999999999]", "autoUnmask": true }).mask(document.querySelectorAll(".mask-codigo"));
      inputmask({ mask: "9999", "autoUnmask": true }).mask(document.querySelectorAll(".mask-year"));

      console.log(document.querySelectorAll(".mask-date"));
    }, 500);
  }

  getBase(type) {
    return this.http.get(this.url + "/base", { params: { type } })
      .toPromise()
      .then(result => result)
      .catch(result => Promise.reject(result))
      .finally();
  }

  getEstados() {
    return this.http.get(this.url + "/estado")
      .toPromise()
      .then(result => result)
      .catch(result => Promise.reject(result))
      .finally();
  }

  getCidades(code) {
    return this.http.get(this.url + "/estado/" + code)
      .toPromise()
      .then(result => result)
      .catch(result => Promise.reject(result))
      .finally();
  }


  getErrorMessage(element) {
    if (element.errors != null && element.errors.required == true) {
      return "Esse campo é obrigatório";
    }
    if (element.errors != null && element.errors.email == true) {
      return "Esse campo não é um e-mail válido.";
    }
    if (element.errors != null && element.errors.exists == true) {
      return "Esse valor já existe.";
    }

    return JSON.stringify(element.errors);
  }

  hasError(field) {
    if (field.invalid && (field.dirty || field.touched)) {
      return true;
    }
    if (field.invalid && field._parent != null && field._parent.submitted) {
      return true;
    }
    return false;
  }


  generateTable(columns, order, buttons, service, filters?) {
    let options:any = {
      lengthChange: false,
      "language": {
        "url": "assets/Portuguese-Brasil.json"
      },
      serverSide: true,
      "pageLength": 15,
      "info": false,
      searching: false,
      "autoWidth": false,
      "order": order,
      "columns": columns,
      ajax: (data, callback, settings) => {
        this.loading = true;
        let page = (data.start/data.length) + 1;
        console.log("page",page);
        let order = { order: { column: data.columns[data.order[0].column].name, dir: data.order[0].dir }, filters: filters, page: page, length: data.length };
        service.find(order)
          .then((result: any) => {
            result['recordsTotal'] = result.total;
            result['recordsFiltered'] = result.total;
            callback(result);
          })
          .catch()
          .finally(() => this.loading = false);
        // callback();
      },
      'columnDefs': [
        {
          "targets": '_all',
          'createdCell': function (td, cellData, rowData, row, col) {
            let header = $("#tableData").find("thead").find("th")[col];
            if ($(header).attr("data-size-length") != null && $(header).attr("data-size-length").length > 0) {
              $(td).attr("data-size-length", $(header).attr("data-size-length"));
              $(td).addClass("js-crop-text");
            }

          }
        }
      ],
      rowCallback: (row, data, index) => { this.addRegisterOnRow(row, data, index, buttons); },
    };
    let table = $("#tableData").DataTable(options);

    table.buttons().container()
      .appendTo('#tableData_wrapper .col-md-6:eq(0)');

    table.on('draw.dt', () => {
      table.$(".js-tooltip").tooltip({ trigger: "hover" });
      table.$(".js-tooltip").on("click", () => {
        table.$(".js-tooltip").tooltip("hide");
      });
    });
    return table;
  }

  addRegisterOnRow(row, data, index, buttons) {
    // console.log(row, data, index);
    $.each($(row).find(".js-crop-text"), (index, td) => {
      let title = $(td).html();
      let shortText = title;
      if (title.length > $(td).attr("data-size-length")) {
        shortText = $.trim(title).substring(0, $(td).attr("data-size-length"))
          .trim(this) + "...";
      }
      $(td).html(shortText);
    });

    $(row).find(".js-action-button-dt").append("<br/>");

    buttons.forEach((element, index) => {
      // console.log(element, index);
      let button = document.createElement("button");
      $(button).addClass("btn");
      $(button).addClass("btn-icon");
      $(button).addClass("js-tooltip");
      if (element.conditions == null) {
        $(button).attr("title", element.label);
        $(button).html("<i class=\"" + element.icon + "\"></i>");
      }
      else {
        element.conditions.forEach((condition, index2) => {
          // console.log(condition.icon);
          if (condition.value == data[element.column]) {
            $(button).attr("title", condition.label);
            $(button).html("<i class=\"" + condition.icon + "\"></i>");
          }
        });
      }

      $(button).on("click", (event) => {
        element.action(data);
        event.preventDefault();
      });
      if (index > 0) {
        $(row).find(".js-action-button-dt").append("&nbsp;&nbsp;");
      }
      $(row).find(".js-action-button-dt").append(button);
    });

  }

}
