import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalService } from '../global.service';
import { Http } from '../http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    url;
    constructor(private globalService: GlobalService, private http: Http, private router: Router) {
        this.url = environment.url;
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.http.get(this.url + "/user-auth")
            .toPromise()
            .then((result: any) => {
                this.globalService.user = result;
                return true;
            })
            .catch(result => {
                this.router.navigate(["/auth/login"])
                return false;
            });
    }
}
