import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './layout/base/base.component';
import { AuthGuard } from './auth/auth.guard';
import { AccountComponent } from './account/account.component';

const routes: Routes = [
  {
    path: "",
    redirectTo: '/dashboard', pathMatch: 'full'
  },
  {
    path: "auth",
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'dashboard',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'myaccount',
        component: AccountComponent
      },
      {
        path: 'imovel',
        loadChildren: () => import('./imovel/default.module').then(m => m.CustomModule),
      },
      {
        path: 'user',
        loadChildren: () => import('./user/default.module').then(m => m.CustomModule),
      },
      {
        path: 'corretor',
        loadChildren: () => import('./corretor/default.module').then(m => m.CustomModule),
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
