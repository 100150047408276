import { Component, OnInit } from '@angular/core';
import { GlobalService } from './global.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'herzog-ui';

  constructor(public globalService: GlobalService,
    private router: Router) { }

  ngOnInit() {
    /* setTimeout(() => {
      let script = document.createElement("script");
      script.src = "assets/js/app.js";
      document.querySelector("body").appendChild(script);
    }, 200); */

    this.router.events.subscribe(event => {
      // console.log(event, this.activeRoute);
      if (event instanceof NavigationStart) {
        this.globalService.loading = true;
      } else if (event instanceof NavigationEnd) {
        this.globalService.loading = false;
        setTimeout(() => {
          if (!document.querySelectorAll("script[src=\"assets/js/app.js\"]").length) {
            let script = document.createElement("script");
            script.src = "assets/js/app.js";
            document.querySelector("body").appendChild(script);
          }
        }, 500);
      }
    });
  }
}
