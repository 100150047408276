import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseComponent } from './base/base.component';
import { MenuComponent } from './menu/menu.component';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [BaseComponent, MenuComponent, NavbarComponent],
  entryComponents: [MenuComponent],
  imports: [
    CommonModule,
    RouterModule,
  ]
})
export class LayoutModule { }
