import { Component, OnInit } from '@angular/core';
import { GlobalService } from 'src/app/global.service';
import { AuthService } from 'src/app/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  year = new Date().getFullYear();

  constructor(
    public globalService: GlobalService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
  }


  ngOnInit() {
  }

  logout() {
    this.globalService.loading = true;
    this.authService.logout()
      .then((result) => {
        this.router.navigate(["/auth/login"])
      })
      .catch((result) => {
      })
      .finally(() => this.globalService.loading = false)
  }

}
