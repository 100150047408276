// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const url = "//api.herzog.com.br";
export const environment = {
  production: false,
  url: url + "/api",
  tokenWhitelistedDomains: ["api.herzog.com.br"],
  tokenBlacklistedRoutes: [
    url + '/oauth/token',
    url + '/api/forgot',
    url + '/api/forgot/done',
  ],
  authorization: 'Basic Mjo3SnluaUp6UVlIblFsbFcxdDdUdmVGNzhLT011MkN1bUI1M3FLckky',
  urlSecurity: url
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
