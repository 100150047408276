import { Component, OnInit } from '@angular/core';
import { CustomService } from '../custom.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { GlobalService } from '../global.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  objeto: any = {};

  constructor(public globalService: GlobalService,
    private service: CustomService,
    private toastr: ToastrService,
    private router: Router) {
    this.globalService.actualPageTitle = "Minha conta"
    this.objeto = this.globalService.user;
    this.service.path = "account";
  }

  ngOnInit() {

  }

  save(f) {
    if (f.invalid)
      return;
    this.globalService.loading = true;

    this.service.update(this.objeto)
      .then(() => {
        this.toastr.success('Registro salvo com sucesso');
      })
      .catch((result) => {
        console.log("error", result);
      })
      .finally(() => this.globalService.loading = false)
  }
}
