import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    url = environment.urlSecurity;
    oauthTokenUrl: string;
    oauthTokenUrlDelete: string;
    jwtPayload;

    constructor(private http: HttpClient, private jwtHelper: JwtHelperService) {
        this.oauthTokenUrl = this.url + "/oauth/token";
        this.oauthTokenUrlDelete = this.url + "/api/logout";
        this.carregarToken();
    }

    generateHeaders(): Promise<any> {
        // console.log("accessTokenInvalido?", this.isAccessTokenInvalido());
        if (this.isAccessTokenInvalido()) {
            // console.log("Access token inválido");
            return this.obterNovoAccessToken().then(() => this.generateHeaders())
                .catch(error => { console.log("Erro ao obter novo access token"); });
        }
        // console.log("Tudo certo?");
        const headers = { 'Authorization': "Bearer " + localStorage.getItem("token") };
        // console.log("Tudo certo na bahia", headers);
        return Promise.resolve(headers);
    }

    login(email: string, password: string): Promise<void> {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', environment.authorization);

        const body = `username=${email}&password=${password}&grant_type=password`;

        return this.http.post<any>(this.oauthTokenUrl, body,
            { headers, withCredentials: true })
            .toPromise()
            .then(response => {
                this.armazenarToken(response.access_token);
            })
            .catch(response => {
                if (response.status === 400) {
                    if (response.error.error === 'invalid_grant') {
                        return Promise.reject({
                            error: {
                                message: 'Usuário ou senha inválida!'
                            }
                        });
                    }
                }

                return Promise.reject(response);
            });
    }

    obterNovoAccessToken(): Promise<void> {
        const headers = new HttpHeaders()
            .append('Content-Type', 'application/x-www-form-urlencoded')
            .append('Authorization', environment.authorization);

        const body = 'grant_type=refresh_token';

        return this.http.post<any>(this.oauthTokenUrl, body,
            { headers, withCredentials: true })
            .toPromise()
            .then(response => {
                this.armazenarToken(response.access_token);

                // console.log('Novo access token criado!');

                return Promise.resolve(null);
            })
            .catch(response => {
                // console.error('Erro ao renovar token.', response);
                return Promise.resolve(null);
            });
    }

    limparAccessToken() {
        console.log("Clearing access Token");
        localStorage.removeItem('token');
        this.jwtPayload = null;
    }

    isAccessTokenInvalido() {
        const token = localStorage.getItem('token');

        return !token || this.jwtHelper.isTokenExpired(token);
    }

    private armazenarToken(token: string) {
        this.jwtPayload = this.jwtHelper.decodeToken(token);
        localStorage.setItem('token', token);
    }

    private carregarToken() {
        const token = localStorage.getItem('token');

        if (token) {
            this.armazenarToken(token);
        }
    }

    recovery(email) {
        return this.http.post(this.url + "/api/forgot", { email })
            .toPromise()
            .then(success => success)
            .catch(error => Promise.reject(error));
    }

    recoveryDone(email, password, token) {
        let form = new FormData();
        form.append("email", email);
        form.append("password", password);
        form.append("token", token);
        const options = { headers: { "Content-Type": "application/json" } };
        return this.http.post(this.url + "/api/forgot/done", { email, password, token }, options)
            .toPromise()
            .then(success => success)
            .catch(error => Promise.reject(error));
    }

    logout() {
        return this.http.get<any>(this.oauthTokenUrlDelete)
            .toPromise()
            .then(success => {
                this.limparAccessToken();
            })
            .catch(error => Promise.reject(error));
    }


}
