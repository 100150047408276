import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Http } from './http';

@Injectable({
    providedIn: 'root'
})
export abstract class CustomService {

    url = environment.url;

    path;

    constructor(protected http: Http) { }

    find(objeto?) {
        let params: any = {};
        if (objeto != null && objeto.order != null)
            params.order = JSON.stringify(objeto.order);
        if (objeto != null && objeto.word != null && objeto.column != null)
            params[objeto.column] = objeto.word;
        if (objeto != null && objeto.code != null)
            params["code"] = objeto.code;
        if (objeto != null && objeto.no_paginate != null)
            params["no_paginate"] = true;
        if (objeto != null && objeto.filters != null) {
            params['filters'] = JSON.stringify(objeto.filters);
        }
        if (objeto != null && objeto.page != null) {
            params['page'] = objeto.page;
        }
        if (objeto != null && objeto.page != null) {
            params['length'] = objeto.length;
        }

        return this.http.get(this.url + "/" + this.path, { params })
            .toPromise()
            .then(result => result)
            .catch(result => Promise.reject(result));
    }


    insert(objeto) {
        return this.http.post(this.url + "/" + this.path, objeto)
            .toPromise()
            .then(result => result)
            .catch(result => Promise.reject(result));
    }

    update(objeto) {
        return this.http.put(this.url + "/" + this.path + "/" + objeto.code, objeto)
            .toPromise()
            .then(result => result)
            .catch(result => Promise.reject(result));
    }

    remove(objeto) {
        return this.http.delete(this.url + "/" + this.path + "/" + objeto.code)
            .toPromise()
            .then(result => result)
            .catch(result => Promise.reject(result));
    }

    get(code) {
        return this.http.get(this.url + "/" + this.path + "/" + code)
            .toPromise()
            .then(result => result)
            .catch(result => Promise.reject(result));
    }

}
